import React from 'react';
import { Helmet } from 'react-helmet';
import { toUpperCase } from '../helpers/strings';

export const Seo = ({ title, metaDescription, slug, blog }) => {
	const schemaCor = {
		'@context': 'http://schema.org',
		'@type': 'Corporation',
		'@id': `https://corporation.dereader.ca`,
		url: 'https://www.dereader.ca',
		legalName: 'De-Pipe Reader Ltd.',
		alternateName: 'DeReader',
		description:
			'DeReader is everything you need to manage your business and personal assets, in the palm of your hands.',
		address: {
			'@type': 'PostalAddress',
			addressLocality: 'Calgary',
			addressCountry: 'CA',
		},
		logo:
			'https://firebasestorage.googleapis.com/v0/b/dereader-7bdbf.appspot.com/o/logo.png?alt=media&token=b5c983c4-09e8-4614-a3eb-a16fe0412a00',
		sameAs: [
			'https://www.instagram.com/dereader_1/',
		],
		additionalType: 'http://www.productontology.org/doc/File_manager',
		telephone: '+14035750027'
	};

	const schemaOrgWebPage = {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		url: `https://www.dereader.ca${slug}`,
		headline: title,
		inLanguage: 'en',
		mainEntityOfPage: `https://corporation.dereader.ca`,
		description: metaDescription,
		name: title,
		author: {
			'@type': 'Corporation',
			name: 'De-Pipe Reader Ltd.'
		},
		copyrightHolder: {
			'@type': 'Corporation',
			name: 'De-Pipe Reader Ltd.'
		},
		copyrightYear: '2019',
		creator: {
			'@type': 'Corporation',
			name: 'De-Pipe Reader Ltd.'
		},
		publisher: {
			'@type': 'Corporation',
			name: 'De-Pipe Reader Ltd.'
		},
		datePublished: new Date(1556672400000),
		dateModified: new Date(),
		image: {
			'@type': 'ImageObject',
			url:
				'https://firebasestorage.googleapis.com/v0/b/dereader-7bdbf.appspot.com/o/logo.png?alt=media&token=b5c983c4-09e8-4614-a3eb-a16fe0412a00'
		}
	};

	let schemaArticle = null;

	if (blog) {
		schemaArticle = {
			'@context': 'http://schema.org',
			'@type': 'Article',
			author: {
				'@type': 'Person',
				name: blog.author.name
			},
			copyrightHolder: {
				'@type': 'Corporation',
				name: 'De-Pipe Reader Ltd.'
			},
			copyrightYear: '2019',
			creator: {
				'@type': 'Corporation',
				name: 'De-Pipe Reader Ltd.'
			},
			publisher: {
				'@type': 'Corporation',
				name: 'De-Pipe Reader Ltd.',
				logo: {
					'@type': 'ImageObject',
					url:
						'https://firebasestorage.googleapis.com/v0/b/dereader-7bdbf.appspot.com/o/logo.png?alt=media&token=b5c983c4-09e8-4614-a3eb-a16fe0412a00'
				}
			},
			datePublished: blog.createdAt,
			dateModified: blog.updatedAt,
			description: blog.description,
			headline: blog.title,
			inLanguage: 'en',
			url: `https://dereader.ca/blog/${blog.slug}`,
			name: blog.title,
			image: {
				'@type': 'ImageObject',
				url: blog.image.fluid.src
			},
			mainEntityOfPage: `https://corporation.dereader.ca`
		};
	}

	return (
		<Helmet title={`${title ? `${toUpperCase(title)} - ` : ''}DeReader`}>
      <link rel="canonical" href={`https://dereader.ca${slug}` }/>
			<meta name='description' content={metaDescription} />
			<meta property='og:title' content={`${title ? `${toUpperCase(title)} - ` : ''}DeReader`} />
			<meta property='og:description' content={metaDescription} />
			<meta property='og:type' content={'website'} />
			<meta
				property='og:image'
				content={
					'https://firebasestorage.googleapis.com/v0/b/dereader-7bdbf.appspot.com/o/logo.png?alt=media&token=b5c983c4-09e8-4614-a3eb-a16fe0412a00'
				}
			/>
			<meta property='og:image:alt' content={'DeReader - Scan. Share. Collaborate.'} />
			<meta property='og:site_name' content={'DeReader'} />
			<meta property='og:url' content={`https://www.dereader.ca${slug}`} />

			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:creator' content={'@latticestudios'} />
			<meta
				name='twitter:image'
				content={
					'https://firebasestorage.googleapis.com/v0/b/dereader-7bdbf.appspot.com/o/logo.png?alt=media&token=b5c983c4-09e8-4614-a3eb-a16fe0412a00'
				}
			/>
			<meta name='twitter:title' content={`${title ? `${toUpperCase(title)} - ` : ''}DeReader`} />
			<meta name='twitter:description' content={metaDescription} />
			<meta name='twitter:site' content={'DeReader'} />

			<script type='application/ld+json'>{JSON.stringify(schemaCor)}</script>
			{!blog && <script type='application/ld+json'>{JSON.stringify(schemaOrgWebPage)}</script>}
			{blog && <script type='application/ld+json'>{JSON.stringify(schemaArticle)}</script>}
		</Helmet>
	);
};
